<div class="container">
    <div class="row-col mb-2">
        <div class="col-2">
            <label>Филиал</label>
            <Multiselect
                    v-model="selectionValues.branch_codes"
                    :options="branches"
                    :label="'full_name'"
                    placeholder="Выбор филиала"
                    valueProp="Ref_Key"
                    trackBy="full_name"
                    mode="tags"
                    :searchable="true"
                    @change="selectBranch"
            />

        </div>
        <div class="col-2">
            <label>Менеджер</label>
            <Multiselect
                    mode="single"
                    v-model="selectionValues.manager_id"
                    :options="managers"
                    :label="'username'"
                    valueProp="id"
                    trackBy="username"
                    placeholder="Выбор менеджера"
                    :searchable="true"
                    @change="selectManager"
            />
        </div>
        <div class="col-2">
            <label>Статус закрытия</label>
            <Multiselect
                    v-model="selectionValues.close_statuses"
                    :options="statusFilters.closeStatuses"
                    :label="'name'"
                    valueProp="id"
                    mode="tags"
                    trackBy="name"
                    :placeholder="'Статус закрытия'"
                    :searchable="true"
                    @change="selectCloseStatus"

            />

        </div>
        <div class="col-2">
            <label>Статус</label>
            <Multiselect
                    v-model="selectionValues.statuses"
                    :options="statusFilters.statuses"
                    :label="'name'"
                    valueProp="id"
                    mode="tags"
                    trackBy="name"
                    :placeholder="'Статус'"
                    :searchable="true"
                    @change="selectStatus"

            />

        </div>
    </div>
    <div class="row-col mt-3 mb-3">
        <div class="col-2">
            <Input
                    :label="'Контрагент'"
                    :placeholder="'Выбор контрагента'"
                    v-on:change="selectPartner"
            />
        </div>
        <div class="col-2">
            <Input
                    :label="'Код контрагента'"
                    :placeholder="'Выбор по коду контрагента'"
                    v-on:change="selectPartnerCode"
            />
        </div>
        <div class="col-2">
            <label>Оферта</label>
            <select
                    @input="selectOffer"
            >
                <option disabled selected value="">Выбрать оферту</option>
                <option value="0">Не отправлена</option>
                <option value="1">Отправлена</option>
            </select>
        </div>
        <div class="col-2">
            <label>Законтрактован</label>
            <select
                    @input="selectContract"
            >
                <option disabled selected value="">Законтрактован</option>
                <option value="0">Нет</option>
                <option value="1">Да</option>
            </select>
        </div>
    </div>
    <div class="row-col mt-3 mb-3">
        <div class="col-2">
            <label>Опросник</label>
            <select
                    @input="selectQuest"
            >
                <option disabled selected value="">Опросник</option>
                <option value="0">Не пройден</option>
                <option value="1">Пройден</option>
            </select>
        </div>
        <div class="col-2">
            <label>Дата создания с:</label>
            <datepicker
                    v-model="date_from"
                    class="picker"
                    placeholder="Дата от"
            />
        </div>
        <div class="col-2">
            <label>Дата создания по:</label>
            <datepicker
                    v-model="date_to"
                    placeholder="Дата до"
            />
        </div>
    </div>
    <div>
        <Grid
                :columns="[
                {
                    name: 'order_num',
                    label: 'Номер заказа',
                    width: 'minmax(100px, 2.00fr)',
                },
                {
                    name: 'order_created',
                    label: 'Дата создания заказа',
                    width: 'minmax(150px, 3.33fr)',
                },
                {
                    name: 'total',
                    label: 'Сумма заказа',
                    width: 'minmax(100px, 2fr)',
                },
                {
                    name: 'status',
                    label: 'Статус заказа',
                    width: 'minmax(100px, 2fr)',
                },
                {
                    name: 'closing_status',
                    label: 'Статус закрытия заказа',
                    width: 'minmax(100px, 2fr)',
                },
                {
                    name: 'manager_name',
                    label: 'Менеджер',
                    width: 'minmax(150px, 3.33fr)',
                },
                {
                    name: 'partner_name',
                    label: 'Контрагент',
                    width: 'minmax(150px, 3.33fr)',
                },
                {
                    name: 'branch_name',
                    label: 'Филиал',
                    width: 'minmax(100px, 2fr)',
                },
                {
                    name: 'is_contracted',
                    label: 'Законтрактован',
                    width: 'minmax(50px, 1fr)',
                },
                {
                    name: 'offer',
                    label: 'Оферта',
                    width: 'minmax(100px, 2fr)',
                },
                {
                    name: 'questions_complete',
                    label: 'Опросник',
                    width: 'minmax(100px, 2.00fr)',
                },
                {
                    name: 'actions',
                    label: 'Перейти в опросник',
                    width: 'minmax(100px, 2fr)',
                },
            ]"
                :onLink="redirectToResult"
                :data="orders"
        ></Grid>
    </div>
    <div class="pagination__wrapper">
        <ul class="pagination">
            <li>
                <button class="prev" @click="prevPage" :disabled="pageNumber===0">&#10094;</button>
            </li>
            <template v-for="(number,key) in allPages">
                <li @click="key !== pageNumber ? openPage(key) : ''"
                    v-if="key === 0 || number === allPages || (pageNumber + 7 >= key && pageNumber - 7 <= key)">
                    <button
                            v-bind:class="{'active':pageNumber === key,'side_numbers':key === 0 || number === allPages}">
                        {{ number }}
                    </button>
                </li>
            </template>
            <li>
                <button class="next" @click="nextPage" :disabled="pageNumber">&#10095;</button>
            </li>
        </ul>
    </div>
</div>
