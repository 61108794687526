<template src="./templates/orders.html"></template>

<script>
import Multiselect from '@vueform/multiselect';
import Grid from '@c/Grid';
import '../../layouts/default/styles/orders.scss';
import {
    getOrders,
    getCountOrders,
    // getBranches,
    getManagers,
    getFilters
} from '@api/orders';
import Datepicker from 'vue3-datepicker';
import Input from '@f/Input';


export default {
    name: 'Orders',
    components: {
        Multiselect,
        Grid,
        Input,
        Datepicker
    },
    props: {
        size: {
            type: Number,
            required: false,
            default: 15
        }
    },
    data() {
        return {
            date_from: null,
            date_to: null,
            orders: null,
            branches: [],
            managers: [],
            statusFilters: {statuses: [], closeStatuses: []},
            pageNumber: 0,
            allPages: 0,
            countOrders: 0,
            statuses: [],
            closeStatuses: [],

            selectionValues: {
                branch_codes: null,
                manager_id: null,
                partner_string: null,
                partner_code: null,
                offer: null,
                contract: null,
                quest: null,
                date_from: null,
                date_to: null,
                close_statuses: null,
                statuses: null,
                partnerId: null
            },
        };
    },
    computed: {
        partnerId() {
            return this.$route.query.partnerId || null;
        },
    },
    watch: {
        date_from(newDate) {
            const date = new Date(newDate);
            this.selectDateFrom(date.toLocaleDateString());
        },
        date_to(newDate) {
            const date = new Date(newDate);
            console.log(newDate);
            this.selectDateTo(date.toLocaleDateString());
        }
    },
    async created() {
        this.selectionValues.partnerId = this.partnerId;
        await this.compileData(this.pageNumber * 15);
        this.branches = this.$store.state.Catalogs.branches;
        this.managers = await getManagers();
        this.statusFilters = await getFilters();
    },
    methods: {
        async nextPage() {
            this.pageNumber++;
            await this.compileData(this.pageNumber * 15);
        },
        async prevPage() {
            this.pageNumber--;
            await this.compileData(this.pageNumber * 15);
        },
        async openPage(key) {
            this.pageNumber = key;
            await this.compileData(this.pageNumber * 15);
        },
        async selectBranch(branch) {
            this.selectionValues.branch_codes = branch;
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectCloseStatus(status) {
            console.log(status);
            this.selectionValues.close_status = status.id;
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectStatus(status) {
            this.selectionValues.status = status.id;
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async clearStatus(status) {
            console.log(status, 'clearStatus');
            this.selectionValues.status = status.id;
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectManager(managerId) {
            this.selectionValues.manager_id = managerId;
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectPartner(e) {
            this.selectionValues.partner_string = e.target.value.length > 0 ? e.target.value : null;
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectPartnerCode(e) {
            this.selectionValues.partner_code = e.target.value.length > 0 ? e.target.value : null;
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectDateFrom(date) {
            this.selectionValues.date_from = date.length > 0 ? date : null;
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectDateTo(date) {
            this.selectionValues.date_to = date.length > 0 ? date : null;
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectOffer(e) {
            this.selectionValues.offer = parseInt(e.target.value, 2);
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectContract(e) {
            this.selectionValues.contract = parseInt(e.target.value, 2);
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async selectQuest(e) {
            this.selectionValues.quest = parseInt(e.target.value, 2);
            this.pageNumber = 0;
            await this.compileData(0);
        },
        async compileData(offset) {
            this.orders = await getOrders(offset, this.selectionValues);
            this.countOrders = await getCountOrders(this.selectionValues);
            this.allPages = Math.ceil(this.countOrders / 15);
        },
        redirectToResult(question) {
            if (question.version > 0) {
                this.$router.push({path: '/questionnaire/edit', query: {result_code: question.result_code}});
            } else {
                window.location = `https://lbr.ru/crm/questionnaire?edit=${question.result_code}`;
            }
        }
    }
};
</script>
<style src="@vueform/multiselect/themes/default.css"></style>
